<template>
	<div
		style="
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100vh%;
			width: 100vw;
		"
	>
		<div style="width: 100px; height: 100vh; align-items: center; display: flex">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
				<rect fill="#009FEE" stroke="#009FEE" stroke-width="15" width="30" height="30" x="25" y="85">
					<animate
						attributeName="opacity"
						calcMode="spline"
						dur="2"
						values="1;0;1;"
						keySplines=".5 0 .5 1;.5 0 .5 1"
						repeatCount="indefinite"
						begin="-.4"
					></animate>
				</rect>
				<rect fill="#009FEE" stroke="#009FEE" stroke-width="15" width="30" height="30" x="85" y="85">
					<animate
						attributeName="opacity"
						calcMode="spline"
						dur="2"
						values="1;0;1;"
						keySplines=".5 0 .5 1;.5 0 .5 1"
						repeatCount="indefinite"
						begin="-.2"
					></animate>
				</rect>
				<rect fill="#009FEE" stroke="#009FEE" stroke-width="15" width="30" height="30" x="145" y="85">
					<animate
						attributeName="opacity"
						calcMode="spline"
						dur="2"
						values="1;0;1;"
						keySplines=".5 0 .5 1;.5 0 .5 1"
						repeatCount="indefinite"
						begin="0"
					></animate>
				</rect>
			</svg>
		</div>
	</div>
</template>
<script>
export default {
	name: "loadingLayout",
};
</script>
